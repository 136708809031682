import { API_URL } from './api';

function getChannel(channelId) {
  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                channel: getYoutubechannel(ytChannelId: "${channelId}") {
                  title
                  thumbnailUrl
                  subscriberCount
                  viewCount
                  videoCount
                  ytChannelId
                  relatedPlaylists {
                    uploads
                  }
                  items: videos {
                    ... on YouTubeVideo {
                      id: ytVideoId
                      title
                      views
                      publishDate
                      length
                      thumbnailUrl
                      channel {
                        channelId: ytChannelId
                        thumbnailUrl
                        title
                      }
                    }
                  }
                }
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          return data || {};
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return {};
        }
      );

}

export default getChannel;
