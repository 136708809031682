import { Link } from 'react-router-dom';

import './SideBarSection.css';
import draggableIcon from '../icons/draggable.png';
import Bubble from './Bubble';

function SideBarItem({ id, route, title, image, hue, symbol }) {
  return (
    <div className="SideBarItem">
      <Link to={`/${route}/${id}`} className="SideBarItemLeft">
        {/* <div className="SideBarItemLeft"> */}
          <Bubble
            image={image}
            hue={hue}
            seed={id}
            symbol={(symbol) ? symbol : ""}
          />
          <div className="SideBarItemTitle">
            {title}
          </div>
        {/* </div> */}
      </Link>
      <div className="SideBarItemHandle">
        <img src={draggableIcon} alt=""/>
      </div>
    </div>
  );
}

function SideBarSection({ title, route, items}) {
  return (
    <div className="SideBarSection">
      <div className="SideBarHeading">
        <div className="SideBarHeading-title">
          { title }
        </div>
        <div className="SideBarHeadingButtons">
          <div className="SideBarHeadingButton">
          {"Ξ"}
          </div>
          <div className="SideBarHeadingButton SideBarHeadingButton-add">
          {"+"}
          </div>
        </div>
      </div>
      <div className="SideBarItems">
        {items.map((item) => <SideBarItem
          key={item.id}
          id={item.id}
          route={route}
          title={item.title}
          image={item.bubbleImage}
          hue={item.hue}
          symbol={item.emoji}
          />)
        }
      </div>
      <div className="SideBarFooter">
        <Link to='/feeds'>
          <div className="SideBarSeeAll">
            See all
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SideBarSection;
