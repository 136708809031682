import { useState } from 'react';

import './Feeds.css';

import Carousel from '../components/Carousel';
import FeedItems from '../components/FeedItems';

function Feeds({ feeds }) {
  return (
    <div className="Feeds ContentArea">      
      {
        (feeds || []).map((feed) => <Carousel
          key={feed.id}
          id={feed.id}
          symbol={feed.emoji}
          title={feed.title}
          bubbleImage={feed.bubbleImage}
          hue={feed.hue}
        >
          <FeedItems containerClassName="CarouselItems" items={feed?.items} />
        </Carousel>
        )
      }
    </div>
  );
}

export default Feeds;
