import { API_URL } from './api';

function search(query) {
  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                feed: search(query: "${query}") {
                  query
                  feedAlgo {
                    bubbleImage
                    emoji
                    hue
                    title
                  }
                  items(numItems: 20) {
                    ... on YouTubeVideo {
                      id: ytVideoId
                      title
                      views
                      publishDate
                      length
                      thumbnailUrl
                      channel {
                        channelId: ytChannelId
                        thumbnailUrl
                        title
                      }
                    }
                  }
                }
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          return data || {};
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return {};
        }
      );

}

export default search;
