import { API_URL } from './api';

import getChannel from './getChannel';

function updateFeed({ userId, feedId, title, params }) {
  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              mutation refresh {
                updateFeed(
                  feedId: "${feedId}"
                  userId: "${userId}"
                  title: "${title}"
                  params: "${JSON.stringify(params).replaceAll('"', '\\"')}"
                ) {
                  title
                }
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          return data || {};
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return {};
        }
      );

}

export default updateFeed;
