import { API_URL } from './api';

function searchChannels(query) {
  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                feed: search(query: "${query}") {
                  items {
                    ... on YouTubeVideo {
                      channel {
                        channelId: ytChannelId
                        title
                        thumbnailUrl
                        subscriberCount
                      }
                    }
                  }
                }
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          const items = data?.feed?.items || [];
          const channels = {};
          for (let i = 0; i < items.length; i++) {
            const channel = items[i]?.channel;
            if (!(channel?.channelId in channels)) {
              channels[channel?.channelId] = channel;
            }
          }
          const channelsList = Object.keys(channels).map((key) => channels[key]);
          const sortedChannels = channelsList.sort((a, b) => {
            return (a?.subscriberCount || 0) - b?.subscriberCount || 0;
          }).reverse();
          return sortedChannels;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return [];
        }
      );

}

export default searchChannels;
