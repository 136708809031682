import { DateTime } from 'luxon';

const longAgo = (d) => {
  const now = DateTime.now()
  const units = ['years', 'months', 'days', 'hours', 'minutes'];
  const ago = now.diff(DateTime.fromISO(d), units);
  let agoVal = 0;
  let agoUnit = '';
  for (let u in units) {
    const unit = units[u];
    if (ago.values[unit] > 0) {
      agoVal = ago[unit];
      agoUnit = unit;
      break;
    }
  }

  if (agoVal === 1) {
    // Cut off "s" for single
    agoUnit = agoUnit.slice(0, -1);
  }
  return [agoVal, agoUnit];
};

export default longAgo
