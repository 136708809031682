import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './SearchResults.css';

import FeedItems from '../components/FeedItems';

import search from '../operations/search';

function SearchResults() {
  let { query } = useParams();

  const [feed, setFeed] = useState({});
  
  useEffect(() => {
    search(query).then((data) => setFeed(data?.feed));
  }, [query]);

  return (
    <div className="SearchResults ContentArea">
      <FeedItems items={feed.items}/>
    </div>
  );
}

export default SearchResults;
