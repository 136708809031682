import { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import './TextUpdaterNode.css';

const handleStyle = { left: 10 };

function TextUpdaterNode({ data, id, selected }) {
  const { value, nodeDataChange } = data || {};

  const handleTextChange = useCallback((e) => {
    console.log(e.target.value);
    console.log(id);
    nodeDataChange(id, { ...data, value: e.target.value });
  }, []);

  return (
    <div className={`TextUpdaterNode ${selected ? "TextUpdaterNodeSelected" : ""}`}>
      <Handle type="target" position={Position.Top} />
      <div>
        <label htmlFor="text">Text:</label>
        <input id="text" name="text" value={value} onChange={handleTextChange} />
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" style={handleStyle} />
    </div>
  );
}

export default TextUpdaterNode;
