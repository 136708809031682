import { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';

import './OperationNode.css';

const handleStyle = { left: 10 };

function OperationNode({ data, id, selected }) {
  const { name, inputs, outputs, nodeDataChange } = data || {};

  return (
    <div className={`OperationNode ${selected ? "OperationNodeSelected" : ""}`}>
      {/* <Handle type="target" position={Position.Top} /> */}
      {
        inputs?.map((input, i) => (
          <Handle key={`target_${input.name}`} type="target" position={Position.Top} id={input.name} style={{left: (i + 1) * 10}} />
        ))
      }
      <div>
        {name}
      </div>
      {
        outputs?.map((output, i) => (
          <Handle key={`source_${output.name}`} type="source" position={Position.Bottom} id={output.name} style={{left: (i + 1) * 10}} />
        ))
      }
      {/* <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" style={handleStyle} /> */}
    </div>
  );
}

export default OperationNode;
