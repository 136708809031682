import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './ChannelPage.css';

import FeedItems from '../components/FeedItems';
import Bubble from '../components/Bubble';

import getChannel from '../operations/getChannel';
import refreshChannel from '../operations/refreshChannel';

function ChannelPage() {
  let { channelId } = useParams();

  const [channel, setChannel] = useState({});
  const [refreshLocked, setRefreshLocked] = useState(false);

  
  useEffect(() => {
    getChannel(channelId).then((data) => setChannel(data?.channel))
  }, [channelId]);

  const handleRefreshChannel = () => {
    if (!refreshLocked) {
      console.log('refreshing');
      setRefreshLocked(true);
      refreshChannel(channelId).then((data) => setChannel(data?.channel || {})).then(() => setRefreshLocked(false));
    } else {
      console.log('not refreshing');
    }
  }

  return (
    <div className="ChannelPage ContentArea">
      <div className="ChannelHeader">
        <div className="ChannelHeaderLeft">
          <Bubble
            seed={channel.id || ""}
            symbol={channel.emoji || ""}
            hue={channel.hue}
            image={channel.thumbnailUrl}
          />
          <div className="ChannelTitle">{channel.title}</div>
        </div>
        <div className="ChannelHeaderRight">
          <div className="ChannelHeaderStat">{`${channel.subscriberCount || "x"} subs`}</div>
          <div className="ChannelHeaderStat">{`${channel.videoCount || "x"} videos`}</div>
          <div className="ChannelRefreshButton" onClick={handleRefreshChannel}>🔄</div>
        </div>
      </div>
      <FeedItems items={channel.items}/>
    </div>
  );
}

export default ChannelPage;
