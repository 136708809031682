import { Link } from 'react-router-dom';

import Bubble from './Bubble';
import './Carousel.css';

function Carousel({id, symbol, bubbleImage, hue, title, children}) {
  return (
    <div className="Carousel">
      <Link to={`/feed/${id}`}>
        <div className="CarouselTitleRow">
          <Bubble
            seed={id}
            symbol={symbol}
            image={bubbleImage}
            hue={hue}
          />
          <div className="CarouselTitle">{title}</div>
        </div>
      </Link>
      {/* <div className="CarouselItems">
        {children}
      </div> */}
      {children}
    </div>
  );
}

export default Carousel;
