import { Link } from 'react-router-dom';
import { useState } from 'react';

import './Header.css';
import SearchBar from './SearchBar';

function Header() {
  const [searchBoxActive, setSearchBoxActive] = useState(false);

  const buttonStyle = searchBoxActive ? { display: "none" } : {};
  const boxStyle = searchBoxActive ? { maxWidth: "70%" } : {};

  return (
    <div className="Header">
      <div className="HeaderContent">
        <Link to='/'>
          <div className="HeaderLogo">
              Floofe
          </div>
        </Link>
        <SearchBar setSearchBoxActive={setSearchBoxActive} style={boxStyle} />

        <div className="HeaderButtons" style={buttonStyle}>
          <div className="HeaderButton HeaderJoin">
              Join
          </div>
          <div className="HeaderButton HeaderLogin">
              Login
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
