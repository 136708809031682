import './controller.css';
import './IntegerController.css';

function IntegerController({ paramKey, schema, param, updateParam }) {
  const {
    name,
    description,
    default: defval,
    minimum,
    maximum
  } = schema;


  return (
    <div className="FeedController IntegerController">
      <div className="ControllerName">{name}</div>
      <div className="ControllerInputs">
        <input
          type="range"
          value={param}
          min={minimum}
          max={maximum}
          onChange={(e) => updateParam(paramKey, e.target.value)}
        />
        <input
          type="number"
          value={param}
          min={minimum}
          max={maximum}
          onChange={(e) => updateParam(paramKey, e.target.value)}
        />
      </div>
    </div>
  );
}

export default IntegerController;
