import { Link } from 'react-router-dom';

import Bubble from './Bubble';
import './VideoCard.css';
import abbreviate from '../utils/abbreviate';
import longAgo from '../utils/longAgo';

function VideoCard({id, title, image, channelId, creatorImage, creatorName, views, created, onClick}) {
  const [ago, units] = longAgo(created);
  return (
    <div className="VideoCard" onClick={() => onClick(id)}>
      <div className="VideoCardImage" style={{backgroundImage: `url("${image}")`}}>
        {/* <img src={image} alt=""/> */}
      </div>
      <div className="VideoCardBottom">
        <div className="VideoCardCreator">
        <Link to={`/channel/${channelId}`}>
          <Bubble
            image={creatorImage}
            text={creatorName}
          />
          </Link>
        </div>
        <div className="VideoCardDetails">
          <div className="VideoCardTitle">
            {title}
          </div>
          <div className="VideoCardStats">
            {abbreviate(views)} views - {ago} {units} ago
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoCard;
