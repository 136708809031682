import { useState } from 'react';

import './AlgoAddNodeMenu.css';

function AlgoAddNodeMenu({ addNode, opDefs }) {
  const sortOpsCompare = (a, b) => {
    const aOp = opDefs?.[a];
    const bOp = opDefs?.[b];
    const aBase = aOp?.type?.split('/')[0];
    const bBase = bOp?.type?.split('/')[0];
    // If same base op, compare lexically
    if (aBase === bBase) {
      if (aOp?.name < bOp?.name) {
        return -1;
      }
      if (aOp?.name === bOp?.name) {
        return 0;
      }
      return 1;
    }

    const baseToOrder = {
      'input': 0,
      'output': 1,
      'op': 2,
    }
    return baseToOrder[aBase] - baseToOrder[bBase];
  };

  return (
    <div className="AlgoAddNodeMenu">
      <div className="AlgoAddNodeMenuContent">
        {
          Object.keys(opDefs).sort(sortOpsCompare).map((op) => (
            <div key={op} className="AlgoAddNodeMenuItem" onClick={() => addNode(op)}>{opDefs[op]?.name}</div>
          ))
        }
      </div>
      <div className="AlgoAddNodeMenuButton"> Add Operation</div>
    </div>
  );
}

export default AlgoAddNodeMenu;
