import './SideBar.css';
import SideBarSection from './SideBarSection';

function SideBar({ sections }) {
  return (
    <div className="SideBar">
      {
        (sections || []).map((section) => <SideBarSection
          key={section.id}
          route={section.id}
          title={section.title}
          items={section.items}
        />
        )
      }
    </div>
  );
}

export default SideBar;
