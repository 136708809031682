import './Home.css';

import react from 'react';

import {
  Outlet
} from "react-router-dom";
import Header from './Header';
import SideBar from './SideBar';

class Home extends react.Component {
  render() {
    const { following, feeds } = this.props;
    return (
      <div className="Home">
        <Header />
        <SideBar 
          sections={[
            {
              id: "channel",
              title: "Following",
              items: following
            },
            {
              id: "feed",
              title: "Feeds",
              items: feeds
            }
          ]}
        />
        <Outlet/>
      </div>
    );
  }

}

export default Home;
