import { API_URL } from './api';

// TODO: actually implement this operation (api and query)
function getFeedAlgo(feedAlgoId) {
  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                feedAlgo: getFeedAlgo(feedAlgoId: "${feedAlgoId}") {
                  id: feedAlgoId
                  title
                  emoji
                  hue
                  bubbleImage
                  paramSchema
                  algoDef
                }
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          return data || {};
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return {};
        }
      );

}

export default getFeedAlgo;
