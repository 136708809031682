import { useState, useEffect } from 'react';

import './controller.css';
import './ChannelController.css';
import searchChannels from '../../operations/searchChannels';
import Bubble from '../Bubble';
import getChannelItems from '../../operations/getChannelItems';


function ChannelItemList({ channels, channelClickHandler, classes, decoration }) {
  return (
    <div className={`ChannelItemList ${classes}`}>
      {
        channels.map((channel, i) => (
          <div onClick={(e) => channelClickHandler(channel)} className="ChannelItem" key={i}>
            <Bubble
              image={channel?.thumbnailUrl}
              decoration={decoration}
            />
            <div className="ChannelItemTitle">
              {channel?.title}
            </div>
          </div>
        ))
      }
    </div>
  )
}

function ChannelController({ paramKey, schema, param, updateParam }) {
  const {
    name,
    description,
    default: defval,
    minimum,
    maximum
  } = schema;

  const [query, setQuery] = useState("");
  const [searchChannelsResults, setSearchChannelsResults] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  useEffect(() => {
    if (param && param.length) {
      getChannelItems(param).then((channels) => {
        setSelectedChannels(channels)
      });
    }
  }, [])

  const searchHandler = (e) => {
    if (e.key === 'Enter') {
      searchChannels(query).then((channels) => setSearchChannelsResults(channels));
    }
  };

  const addChannel = (channel) => {
    const channelIds = param;
    if (channelIds.indexOf(channel?.channelId) < 0) {
      const newChannels = [
        ...selectedChannels,
        channel
      ];
      const newChannelIds = [...param, channel?.channelId];
      setSelectedChannels(newChannels);
      updateParam(paramKey, newChannelIds);
    }
  }

  const removeChannel = (channel) => {
    const channelIds = param;
    if (channelIds.indexOf(channel?.channelId) >= 0) {
      const newChannels = selectedChannels.filter(c => c?.channelId !== channel?.channelId);
      const newChannelIds = channelIds.filter(c => c !== channel?.channelId);
      setSelectedChannels(newChannels);
      updateParam(paramKey, newChannelIds);
    }
  }

  return (
    <div className="FeedController ChannelController">
      <div className="ControllerName">{name}</div>

      <ChannelItemList
        channels={selectedChannels}
        channelClickHandler={removeChannel}
        decoration={
          <div className="ChannelItemX">×</div>
        }
      />

      <div className="ControllerInputs">
        <div className="ChannelControllerSearchBox">
          <input
            type="text"
            value={query}
            placeholder="search channels"
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={searchHandler}
          />
        </div>
      </div>

      <ChannelItemList
        channels={searchChannelsResults}
        channelClickHandler={addChannel}
      />
    </div>
  );
}

export default ChannelController;
