import { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactFlow, { applyEdgeChanges, applyNodeChanges, addEdge, MiniMap, Controls, Background } from 'react-flow-renderer';

import './AlgoEditor.css';

import getFeedAlgo from '../operations/getFeedAlgo';

import TextUpdaterNode from '../components/nodes/TextUpdaterNode';
import OperationNode from '../components/nodes/OperationNode';
import ButtonEdge from '../components/edges/ButtonEdge';
import AlgoAddNodeMenu from '../components/AlgoAddNodeMenu';


const opDefs = {
  "input/channels": {
    "name": "Input: channels",
    "type": "input/channels",
    "config": {
      "default": ["UCSw4GdHGBf4usbvuXa9652A"],
      "selector": "channel", // null for static and global value inputs
      "global": null,
    },
    "inputs": [],
    "outputs": [
      {
        "type": "List[Channel]",
        "name": "channels" 
      }
    ],
  },
  "input/watchHistory": {
    "name": "Input: Watch History",
    "type": "input/watchHistory",
    "config": {
      "default": [],
      "selector": null, // null for static and global value inputs
      "global": "watchHistory",
    },
    "inputs": [],
    "outputs": [
      {
        "type": "List[Video]",
        "name": "videos" 
      }
    ],
  },
  "output/output": {
    "name": "Output",
    "type": "output/output",
    "inputs": [
      {
        "type": "List[Union[Channel, Video]]",
        "name": "A" 
      }
    ],
    "outputs": [],
  },
  "op/recentFromChannels": {
    "name": "recentFromChannels",
    "type": "op/recentFromChannels",
    "config": {
      "limit": 100
    },
    "inputs": [
      {
        "type": "List[Channel]",
        "name": "channels" 
      }
    ],
    "outputs": [
      {
        "type": "List[Video]",
        "name": "videos" 
      },
      {
        "type": "List[int]",
        "name": "indices" 
      },
      {
        "type": "List[float]",
        "name": "scores" 
      }
    ],
  },
  "op/math": {
    "name": "math",
    "type": "op/math",
    "config": {
      "formula": "X + log(Y)"	
    },
    "inputs": [
      {
        "type": "List[Union[int, float]]",
        "name": "X" 
      },
      {
        "type": "List[Union[int, float]]",
        "name": "Y" 
      }
    ],
    "outputs": [
      {
        "type": "List[Union[int, float]]",
        "name": "Z" 
      }
    ],
  },
  "op/sort": {
    "name": "sort",
    "type": "op/sort",
    "config": {
      "reverse": true
    },
    "inputs": [
      {
        "type": "List[Any]",
        "name": "list"
      },
      {
        "type": "Union[List[Union[int, float]], List[str]]",
        "name": "X"
      }
    ],
    "outputs": [
      {
        "type": "List[Any]",
        "name": "sorted" 
      }
    ],
  },
  "op/removeBFromA": {
    "name": "removeBFromA",
    "type": "op/removeBFromA",
    "config": {},
    "inputs": [
      {
        "type": "List[T]",
        "name": "A" 
      },
      {
        "type": "List[T]",
        "name": "B"
      }
    ],
    "outputs": [
      {
        "type": "List[T]",
        "name": "FilteredA" 
      }
    ],
  }
}

// const algoDef = {
// 	"nodes": [
// 		{
// 			"type": "input/channels",
//       "id": "1",
// 			"name": "channels",
// 			"config": {
// 				"default": ["UCSw4GdHGBf4usbvuXa9652A"],
// 				"selector": "channel", // null for static and global value inputs
// 				"global": null,
// 			},
//       "position": { x: 100, y: 0 }
// 		},
// 		{
// 			"type": "input/watchHistory",
//       "id": "2",
// 			"name": "watchHistory",
// 			"config": {
// 				"default": [],
// 				"selector": null, // null for static and global value inputs
// 				"global": "watchHistory",
// 			},
//       "position": { x: 100, y: 100 }
// 		},
// 		{
// 			"type": "op/recentFromChannels",
//       "id": "3",
// 			"name": "recentFromChannels",
// 			"config": {
// 				"limit": 100
// 			},
//       "position": { x: 100, y: 200 }
// 		},
// 		{
// 			"type": "op/math",
//       "id": "4",
// 			"name": "math",
// 			"config": {
// 				"formula": "X + log(Y)"	
// 			},
//       "position": { x: 100, y: 300 }
// 		},
// 		{
// 			"type": "op/sort",
//       "id": "5",
// 			"name": "sort",
// 			"config": {
// 				"reverse": true
// 			},
//       "position": { x: 100, y: 400 }
// 		},
//     {
// 			"type": "op/removeBFromA",
//       "id": "6",
// 			"name": "removeBFromA",
// 			"config": {},
//       "position": { x: 100, y: 500 }
// 		},
// 		{
// 			"type": "output/output",
//       "id": "output",
// 			"name": "output",
// 			"config": {},
//       "position": { x: 100, y: 600 }
// 		},
// 	],
// 	"edges": [
//     {
//       "source": "1",
//       "sourceHandle": "channels",
//       "target": "3",
//       "targetHandle": "channels"
//     }
//   ]
// };

const algoDef = {
  nodes: [],
  edges: []
}

const initialNodes = algoDef?.nodes?.map((nodeDef) => {
  const op = nodeDef?.type;
  const opDef = opDefs[op];
  return {
    id: nodeDef?.id,
    type: nodeDef?.type.split('/')?.[0],
    data: {
      ...opDef,
      label: opDef?.name,
    },
    position: nodeDef?.position
  };
});

// const initialEdges = [
//   { id: 'e1-2', type: 'buttonedge', source: '1', target: '2' },
//   { id: 'e2-3', type: 'buttonedge', source: '2', target: '3', animated: true },
// ];

const initialEdges = algoDef?.edges?.map((edgeDef) => {
  return {
    id: edgeDef?.id,
    type:'buttonedge',
    source: edgeDef?.source,
    sourceHandle: edgeDef?.sourceHandle,
    target: edgeDef?.target,
    targetHandle: edgeDef?.targetHandle,
  };
});

function AlgoEditor({ editing }) {
  let { feedAlgoId } = useParams();

  const [feedAlgo, setFeedAlgo] = useState({});

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode, op: OperationNode }), []);
  const edgeTypes = useMemo(() => ({ buttonedge: ButtonEdge }), []);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const nodeDataChange = (id, data) => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              ...data
            }
          };
        } else {
          return node;
        }
      });
    });
  };

  const edgeDataChange = (id, data) => {
    setEdges((edges) => {
      return edges.map((edge) => {
        if (edge.id === id) {
          return {
            ...edge,
            data: {
              ...edge.data,
              ...data
            }
          };
        } else {
          return edge;
        }
      });
    });
  };

  const removeEdge = (id) => {
    setEdges((edges) => (
      edges.map((edge) => (
        edge.id === id ? null : edge
      )).filter(_ => _)
    ))
  };

  const defaultEdgeData = {
    edgeDataChange,
    removeEdge
  };

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (connection) => {
      // TODO: validate that the connection can be made
      // check to make sure target does not already have edge (or remove it if it does)
      // ensure that there is type compatability between source and target
      return setEdges((eds) => addEdge({
        ...connection,
        type: "buttonedge",
        data: defaultEdgeData
      }, eds));
    },
    [setEdges]
  );

  const addNode = useCallback((op) => {
    setNodes((nodes) => {
      const opDef = opDefs[op];
      const newNode = {
        id: "" + nodes?.length,
        type: opDef?.type?.split('/')?.[0] || opDef?.type,
        data: {
          ...opDef,
          label: opDef?.name,
          nodeDataChange
        },
        position: { x: 0, y: 0}
      };
      return [...nodes, newNode];
    });
  }, [setNodes])

  useEffect(() => {
    // Get Feed Algo Definition
    if (feedAlgoId) {
      getFeedAlgo(feedAlgoId).then((data) => {
        setFeedAlgo(data?.feed || {})
      });
    }

    setNodes(initialNodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        nodeDataChange
      }
    })));


    setEdges(initialEdges.map((edge) => ({
      ...edge,
      data: {
        ...defaultEdgeData,
        ...edge?.data,
      }
    })));

    // setEdges(initialEdges);

  }, [feedAlgoId]);
  return (
    <div className="AlgoEditor ContentArea">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={true}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
      >
        <AlgoAddNodeMenu
          opDefs={opDefs}
          addNode={addNode}
        />
        <Controls/>
        {/* <MiniMap/> */}
        <Background/>
      </ReactFlow>
      
    </div>
  );
}

export default AlgoEditor;
