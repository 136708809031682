import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import './EditFeed.css';
import './Feed.css';

import Bubble from '../components/Bubble';
import Button from '../components/Button';
import FeedItems from '../components/FeedItems';

import getFeed from '../operations/getFeed';
import IntegerController from '../components/controllers/IntegerController';
import ChannelController from '../components/controllers/ChannelController';
import updateFeed from '../operations/updateFeed';

const controllerMap = {
  'integer': IntegerController,
  'channel': ChannelController,
}

function EditFeed() {
  const [feed, setFeed] = useState({});
  const [title, setTitle] = useState("");
  const [emoji, setEmoji] = useState("");
  const [params, setParams] = useState({});
  const [paramSchema, setParamSchema] = useState({});
  let { feedId } = useParams();

  const updateParam = (key, value) => {
    console.log('update param');
    console.log(key);
    console.log(value);
    setParams({
      ...params,
      [key]: value
    });
  };

  useEffect(() => {
    getFeed(feedId).then((data) => {
      setFeed(data?.feed || {});
      setTitle(data?.feed?.title || "");
      setEmoji(data?.feed?.emoji || "");
      setParams(data?.feed?.params || {});
      setParamSchema(data?.feed?.feedAlgo?.paramSchema || {});
    });
  }, [feedId]);

  const saveParams = () => {
    console.log('saving params');
    updateFeed({
      userId: "1",
      feedId,
      title,
      params
    }).then((data) => {
      getFeed(feedId).then((data) => {
        setFeed(data?.feed || {});
        setTitle(data?.feed?.title || "");
        setEmoji(data?.feed?.emoji || "");
        setParams(data?.feed?.params || {});
        setParamSchema(data?.feed?.feedAlgo?.paramSchema || {});
      });
    });
  };

  return (
    <div className="EditFeed ContentArea">
      <div className="FeedTitleRow">
        <div className="FeedTitleRowL">
          <div className="EditBubble">
            <Bubble
              seed={feed.id || ""}
              symbol={emoji || ""}
              hue={feed.hue}
              image={feed.bubbleImage}
            />
          </div>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="FeedTitle"/>
        </div>
        <div className="FeedTitleRowR">
          <Button onClick={saveParams}>Save</Button>
          <Link to={`/feed/${feedId}`}>
            <Button inverted>Discard</Button>
          </Link>
        </div>
      </div>
      <div className="EditFeedSplit">
        <FeedItems items={feed.items}/>
        <div className="EditFeedControls">
          {
            paramSchema?.order?.map((key, i) => {
              if (controllerMap[paramSchema?.properties[key]?.controller]) {
                let Controller = controllerMap?.[paramSchema?.properties?.[key]?.controller];
                return <Controller
                  key={key}
                  paramKey={key}
                  schema={paramSchema?.properties[key]}
                  param={params?.[key] ? params?.[key] : paramSchema?.properties[key].default}
                  updateParam={updateParam}
                />
              } else {
                return "";
              }
            })
          }
        </div>
      </div>
    </div>
  );
}

export default EditFeed;
