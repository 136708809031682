import './SearchBar.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function SearchBar({ setSearchBoxActive, style }) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const inputHandler = (e) => {
    //convert input text to lower case
    var q = e.target.value.toLowerCase();
    setQuery(q);
  };

  const submitHandler = (e) => {
    if (e.key === 'Enter') {
      if (query) {
        navigate(`/search/${query}`);
      } else {
        navigate(`/`);
      }
    }
  };

  return (
    <div className="SearchBox" style={style || {}}>
      <div className="SearchBar">
        <input
          type="text"
          placeholder="search"
          value={query}
          onChange={inputHandler}
          onKeyDown={submitHandler}
          onFocus={() => setSearchBoxActive(true)}
          onBlur={() => setSearchBoxActive(false)}
        />
      </div>
    </div>
  );
}

export default SearchBar;
