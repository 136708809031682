import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import './Feed.css';

import Bubble from '../components/Bubble';
import FeedItems from '../components/FeedItems';

import getFeed from '../operations/getFeed';

function Feed({ editing }) {
  const [feed, setFeed] = useState({});
  let { feedId } = useParams();

  useEffect(() => {
    getFeed(feedId).then((data) => setFeed(data?.feed || {}))
  }, [feedId]);
  return (
    <div className="Feed ContentArea">
      <div className="FeedTitleRow">
        <div className="FeedTitleRowL">
          <Bubble
            seed={feed.id || ""}
            symbol={feed.emoji || ""}
            hue={feed.hue}
            image={feed.bubbleImage}
          />
          <div className="FeedTitle">{feed.title}</div>
        </div>
        <div className="FeedTitleRowR">
          { (!editing) ? 
            <Link to={`edit`}>
              <div className="FeedEditButton">✏️</div>
            </Link>
            : ""
          }
        </div>
      </div>
      <FeedItems items={feed?.items} />
    </div>
  );
}

export default Feed;
