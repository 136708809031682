import { API_URL } from './api';

function getChannelItems(channelIds) {
  const queryParts = (channelIds || []).map((channelId, i) => `
    c${i}: getYoutubechannel(ytChannelId: "${channelId}") {
      channelId: ytChannelId
      title
      thumbnailUrl
      subscriberCount
    }
  `);
  const query = queryParts.join('\n');
  console.log(query);

  return fetch(API_URL,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            query: `
              {
                ${query}
              }
            `
          })
        }
      )
      .then(res => res.json())
      .then(
        ({data}) => {
          return channelIds.map((channelId, i) => data?.[`c${i}`]);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
          return [];
        }
      );

}

export default getChannelItems;
