import './App.css';

import react from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";


import Home from './components/Home';

import AlgoEditor from './pages/AlgoEditor';
import Feeds from './pages/Feeds';
import Feed from './pages/Feed';
import SearchResults from './pages/SearchResults';
import ChannelPage from './pages/ChannelPage';
import EditFeed from './pages/EditFeed';
import getFeedsByUserId from './operations/getFeedsByUserId';

class App extends react.Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: "1",
      activeVideo: "https://www.youtube.com/embed/epDPmgmnmM8",
      feeds: [],
      following: [
        {id: "UCSw4GdHGBf4usbvuXa9652A", title: "marselaaa", bubbleImage: "https://yt3.ggpht.com/ytc/AKedOLTgZeWP62sqiyJ6ULi1U5412DXwf0qRxEyAg_LteHo=s176-c-k-c0x00ffffff-no-rj-mo"}
      ]
    };
  }

  componentDidMount() {
    getFeedsByUserId(this.state.userId).then((data) => {
      const feeds = data?.feeds || [];
      this.setState({ feeds });
    });
  }


  render() {
    const { activeVideo, following, feeds } = this.state;

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home
            following={following}
            feeds={feeds}
          />}>

            <Route path="algo">
              <Route path=":feedAlgoId">
                <Route path="edit" element={<AlgoEditor /> } />
                <Route index element={<AlgoEditor /> } />
              </Route>
              <Route path="new" element={<AlgoEditor /> } />
              {/* <Route index element={<AlgoEditor /> } /> */}
            </Route>

            <Route path="feed">
              <Route path=":feedId">
                <Route path="edit" element={<EditFeed /> } />
                <Route index element={<Feed /> } />
              </Route>
              <Route path="new" element={<EditFeed /> } />
              <Route index element={<Feeds
                activeVideo={activeVideo}
                feeds={feeds}
              />} />
            </Route>

            <Route path="feeds">
              <Route index element={<Feeds
                activeVideo={activeVideo}
                feeds={feeds}
              />} />
            </Route>
            
            <Route path="search">
              <Route path=":query" element={<SearchResults /> } />
              {/* <Route index element={<Feeds
                activeVideo={activeVideo}
                feeds={feeds}
              />} /> */}
            </Route>

            <Route path="channel">
              <Route path=":channelId" element={<ChannelPage /> } />
              {/* <Route index element={<Feeds
                activeVideo={activeVideo}
                feeds={feeds}
              />} /> */}
            </Route>

            <Route index element={<Feeds
              activeVideo={activeVideo}
              feeds={feeds}
            />} />
          </Route>
        </Routes>
      </Router>
    );
  }

}

export default App;
