import './FeedItems.css';
import VideoCard from './VideoCard';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import grad from '../utils/grad';

function FeedItems({ items, containerClassName }) {
  const [activeVideo, setActiveVideo] = useState("");
  
  const activateVideo = (videoId) => {
    setActiveVideo(videoId);
  };
  
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  const activeVideoAreaStyles = {
    background: grad("", 330),
    width: `${dimensions.width}px`,
    height: `${Math.ceil(dimensions.width * 9 / 16)}px`,
  };

  return (
      <div ref={targetRef} className={(containerClassName) ? containerClassName : "FeedItems"}>
        {
          items?.map((item) => 
            (item.id !== activeVideo) ?
            <VideoCard
              key={item.id}
              id={item.id}
              title={item.title}
              views={item.views}
              created={item.publishDate}
              channelId={item?.channel.channelId}
              creatorImage={item?.channel?.thumbnailUrl}
              creatorName={item?.channel?.title}
              image={item.thumbnailUrl}
              gif={item.gif}
              onClick={(videoId)=> activateVideo(videoId)}
            />
            :
            <div className="ActiveVideoArea" key={item.id} style={activeVideoAreaStyles}>
              <iframe width={dimensions.width} height={Math.ceil(dimensions.width * 9 / 16)} src={`https://www.youtube.com/embed/${item.id}?modestbranding=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"></iframe>
            </div>
          )
        }
      </div>
  );
}

export default FeedItems;
